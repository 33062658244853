@use "../vendor" as *;
@use "../settings" as *;
@use "../layout" as *;

.homepage {
    main {
        @extend %flex-column;
        padding: 0;

        // .container {
        //     max-width: 75rem;
        //     margin: auto;
        // }
    }

    &-element {
        padding: 40px 0;

        @include media-breakpoint-down(md) {
            padding: 60px 0;
        }

        &:nth-child(odd) {
            background: $white;
        }

        &:nth-child(even) {
            background: $homepage-basecolor-3;
        }
    }

    &-current-articles {
        .news-archive {
            display: flex;
            justify-content: center;
            padding-top: $size-10;

            .news-archive-btn {
                border-radius: 24px;
                background-color: $primary-subtle;
                font-weight: $font-weight-semibold;
                color: $white;
                font-size: $fs-12;
                padding: $size-5 $size-7;
            }
        }
    }

    .member-nav {
        .arrow-up {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 30px solid $homepage-basecolor-1;
            float: right;
            position: relative;
            right: 100px;
            bottom: 36px;

            @media screen and (max-width: 400px) {
                right: 80px;
            }

            @media screen and (max-width: 360px) {
                right: 70px;
            }

            @media screen and (max-width: 345px) {
                right: 50px;
            }
        }

        .new-user-message {
            margin: 15px 0;
            text-align: center;
            padding: 20px 0;
            background: $homepage-basecolor-1;
            border-radius: 5px;

            p {
                margin: 0;
                padding: 0 20px;
            }
        }
    }

    .shortcuts {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        padding: 20px 0;
        border-bottom: 2px solid $homepage-basecolor-2;
        overflow-x: auto;

        @include media-breakpoint-down(sm) {
            justify-content: left;
        }
    }

    .hero {
        background: linear-gradient(180deg, $white 0%, $homepage-basecolor-3 100%);
        padding-bottom: 50px;


        &-content {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        &-top-wrapper {
            @extend %flex-row;
            gap: $size-13;

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
            }
        }

        &-title {
            font-size: 5rem;
            font-weight: $font-weight-bold;
            color: $primary;
            padding-top: 45px;

            @include media-breakpoint-down(md) {
                font-size: $fs-18;
            }
        }

        &-ingress {
            font-size: $fs-14;
            font-weight: $font-weight-normal;
            line-height: 37px;
            color: $primary;

            @include media-breakpoint-down(md) {
                font-size: $fs-11;
                margin-top: 10px;
            }

            a {
                text-decoration: underline;
                color: inherit;
            }

            &-wrapper {
                margin-top: 135px;
                max-width: 495px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: unset;
                    margin-top: 10px;
                }
            }
        }

        &-illustration {
            flex-grow: 1;
            @include media-breakpoint-down(md) {
                display: flex;
                justify-content: center;
            }

            img {
                margin-top: 78px;
                min-width: 300px;
                max-width: 500px;
                // width: 411px;
                // height: 272px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    height: 100%;
                    min-width: unset;
                }
            }
        }

        .afrsearch {
            max-width: 100%;
            padding-top: 20px;
            margin-bottom: 1rem;

            @include media-breakpoint-down(md) {
                max-width: unset;
                width: 100%;
            }

            &-result {
                padding: 10px;
            }

            .info_text {
                color: $primary;
                font-size: $fs-11;
                font-weight: $font-weight-normal;
                line-height: 27px;
                padding-bottom: $size-4;
            }

            form {
                //padding: 2px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.21);
                max-width: 100%;

                @include media-breakpoint-down(md) {
                    max-width: unset;
                    width: 100%;
                }

                input {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }
            }
        }

    }

    .robot-list-button {
        width: 100%;
        height: 100%;

        a {
            border: none;
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: $fs-11;
            font-weight: $font-weight-normal;
            width: 100%;
            height: 3.5rem;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    &-path-to-authorization {
        .pta {
            &-header {
                padding-bottom: 20px;

                h2 {
                    font-size: $fs-20;
                    font-weight: $font-weight-bold;
                    color: $primary;
                }
            }

            &-content {
                display: flex;
                flex-direction: row-reverse;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    gap: 20px;
                }
            }

            &-text {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-ol {
                display: flex;
                flex-direction: column;
            }

            &-li {
                display: flex;
                flex-direction: row;
                gap: 10px;

                &-number {
                    width: 30px;

                    img {
                        width: 30px;
                    }
                }

                &-text {
                    max-width: 90%;
                    font-size: $fs-11;

                    &-header {
                        font-weight: $font-weight-semibold;
                    }
                }
            }

            &-img {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(md) {
                    width: 70%;
                    margin: auto;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
}
